import {GENERATE, GenerateAction} from '../actionTypes';

const initialState = {
  uuids: [],
};

type Action = GenerateAction;

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case GENERATE: {
      return {
        ...state,
        uuids: action.payload.uuids,
      };
    }
    default:
      return state;
  }
}
