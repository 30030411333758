// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-igorko-redirect-js": () => import("/opt/build/repo/.cache/@igorko/redirect.js" /* webpackChunkName: "component---cache-igorko-redirect-js" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("/opt/build/repo/src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-json-index-tsx": () => import("/opt/build/repo/src/pages/json/index.tsx" /* webpackChunkName: "component---src-pages-json-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/opt/build/repo/src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sql-index-tsx": () => import("/opt/build/repo/src/pages/sql/index.tsx" /* webpackChunkName: "component---src-pages-sql-index-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("/opt/build/repo/src/pages/thankYou.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-uuid-tsx": () => import("/opt/build/repo/src/pages/uuid.tsx" /* webpackChunkName: "component---src-pages-uuid-tsx" */),
  "component---src-pages-xml-index-tsx": () => import("/opt/build/repo/src/pages/xml/index.tsx" /* webpackChunkName: "component---src-pages-xml-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

