import {FORMAT, FormatAction} from '../actionTypes';

const initialState = {
  data: '',
};

type Action = FormatAction;

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case FORMAT: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    default:
      return state;
  }
}
