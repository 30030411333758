export const FORMAT = 'FORMAT';
export const GENERATE = 'GENERATE';

export interface FormatAction {
  type: 'FORMAT';
  payload: {
    language: string;
    data: string
  };
}

export interface GenerateAction {
  type: 'GENERATE';
  payload: {
    uuids: string[];
  };
}
